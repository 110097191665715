import React from "react";
import './index.css';
import { Title } from "../../Entities/title";
import { List } from "../../Entities/list";

export function TeamWork(props) {
    const items = [
        { id: 1, text: "Учусь у более опытных, помогаю тем, кому это необходимо." },
        { id: 2, text: "Прежде чем задать вопрос более опытному разработчику - сперва задам его кактусу, который растет перед монитором" },
        { id: 3, text: "Прежде чем предложить решение задачи, если позволяют время и обстоятельства - предпочту задать наводящий вопрос, чтобы разработчик сам пришел к нужному решению." },
        { id: 4, text: "Понимаю о чем говорят разработчики серверной части." },
        { id: 5, text: "Уверен что тестировщик, дизайнер и аналитик - это весьма полезные специалисты." }
    ]
    return (
        <div className="teamwork">
            <Title range={2} text="Работа в команде" />
            <Title range={3} text="Есть опыт работы в командах от 3х до 7ми человек." />
            <List items={items} />
        </div>
    );
}